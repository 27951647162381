import 'core-js/fn/object/assign';
import 'core-js/es6/promise';
import loadCSS from './common/utils/load-css';

/*eslint-disable piggyback/no-restricted-global-extend*/
window.loadCSS = loadCSS;
const prefix = 'ubf-js-';

const browsers = {
	mobile: (navigator.userAgent.match(/mobi/i)),
	firefox: navigator.userAgent.indexOf('Firefox') > -1,
	webKit: !!navigator.userAgent.match(/webkit/i),
	chrome: navigator.userAgent.indexOf('Chrome') > -1,
	safari: navigator.userAgent.indexOf('Safari') > -1,
	opera: !!window.opera || /opera|opr/i.test(navigator.userAgent),
	android: navigator.userAgent.indexOf('Android') > -1,
	iOs: (!!navigator.userAgent.match(/iPad|iPhone|iPod/g)) && !window.MSStream,
	ie: navigator.userAgent.indexOf('Trident/') > -1,
	edge: navigator.userAgent.indexOf('Edge/') > -1
};

browsers.webKit = !browsers.edge && browsers.webKit;
browsers.chrome = !browsers.edge && browsers.chrome;
browsers.safari = !browsers.edge && browsers.safari && !browsers.chrome;
browsers.ms = browsers.ie || browsers.edge;
let htmlClasses = '';
for (const name in browsers) {
	if (browsers.hasOwnProperty(name) && browsers[name]) {
		htmlClasses += ' ' + prefix + name;
	}
}
document.querySelector('html').className += htmlClasses;
